import { AsProps, CSSProps, styled, VariantProps } from '@neui/core';
import { Box } from '@neui/layout';
import { forwardRef, SVGAttributes, HTMLAttributes } from 'react';

import { useComponentId } from '@components/neui-components/utils/id';

type IconFnProps = {
  id: string;
  svgAttrs?: Record<string, any>;
  title: string;
  variant: string;
  size: number;
};

export type IconProps = CSSProps &
  AsProps &
  VariantProps<typeof IconContainer> &
  HTMLAttributes<HTMLElement> & {
    icon: (props: IconFnProps) => string | undefined;
    svgAttrs?: SVGAttributes<SVGSVGElement>;
    size?: 'small' | 'medium' | 'large';
    variant?: 'solid' | 'outline';
    title?: string;
    fill?: string;
  };

const IconContainer = styled(Box, {
  '& > svg': {
    fill: '$text-standard',
    '& path': {},
  },
});

export const Icon = forwardRef<HTMLDivElement, IconProps>(
  (
    {
      icon,
      id,
      svgAttrs,
      title = 'Icon',
      variant = 'outline',
      size = 'medium',
      fill,
      ...rest
    },
    forwardedRef,
  ) => {
    const iconId = useComponentId('neui-icon', id);

    function getSize() {
      switch (size) {
        case 'small':
          return 24;
        case 'medium':
          return 48;
        case 'large':
          return 96;
      }
    }

    const iconContent = icon({
      id: iconId,
      svgAttrs,
      title,
      variant,
      size: getSize(),
    });

    const fillStyle = fill
      ? {
          ['& > svg']: {
            fill,
          },
        }
      : {};
    return (
      <IconContainer
        ref={forwardedRef}
        css={{
          minHeight: getSize(),
          minWidth: getSize(),
          size: getSize(),
          ...fillStyle,
        }}
        {...rest}
        dangerouslySetInnerHTML={{
          __html: iconContent || '',
        }}
      ></IconContainer>
    );
  },
);

Icon.displayName = 'Icon';
