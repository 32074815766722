/* eslint-disable cds/no-img-element, jsx-a11y/alt-text */
import { DetailedHTMLProps, ImgHTMLAttributes } from 'react';

import { useMakeLink } from '@components/Link';

export function Image({
  src: inboundSrc,
  ...rest
}: DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) {
  const makeLink = useMakeLink();
  const src = makeLink({ href: inboundSrc, alwaysPrependBasePath: true });

  return <img src={src} {...rest} />;
}
