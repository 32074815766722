import { LinkProps } from '@components/Link';
import { BuildStage } from '@utils/config';

export type MakeStagedLinkFunc = (
  defaultLink: string,
  stages?: Partial<Record<BuildStage, string>>,
) => string;

export function makeStagedLinkFunction(
  buildStage: BuildStage,
): MakeStagedLinkFunc {
  return (defaultLink, stages) => {
    if (stages === undefined) {
      return defaultLink;
    }

    return stages[buildStage] ?? defaultLink;
  };
}

type DomainParts = {
  sub?: string;
  base: string;
};

export function getDomainParts(testHostname: string): DomainParts | undefined {
  const knownSubdomains = ['www', 'kunden'];

  try {
    const { hostname } = new URL(testHostname);

    const [tld, domain, ...rest] = hostname.split('.').reverse();

    const [sub, ...newRest] = rest.reverse();

    if (knownSubdomains === undefined || knownSubdomains.includes(sub)) {
      const base = [...newRest, domain, tld]
        .filter((x) => x !== null && x !== undefined)
        .join('.');

      return { sub, base };
    } else {
      const base = [sub, ...newRest, domain, tld]
        .filter((x) => x !== null && x !== undefined)
        .join('.');

      return { base };
    }
  } catch (e) {
    return undefined;
  }
}

export function isExternalLink(url?: string): boolean {
  return (url?.startsWith('http://') || url?.startsWith('https://')) ?? false;
}

export function makeLinkTargetProps(
  url?: string,
): Pick<LinkProps, 'target' | 'rel'> {
  if (isExternalLink(url)) {
    return {
      target: '_blank',
      rel: 'noopener noreferrer',
    };
  }

  return {};
}
