import { AsProps, css, CSSProps, styled } from '@neui/core';
import { Box } from '@neui/layout';
import { forwardRef, HTMLAttributes } from 'react';
import { banking___atm } from '@neui/styleguide-commerzbank';

import { Icon, IconProps } from './Icon';
import { BannerBadgeLook } from './BannerTextBadge';

export type BannerIconBadgeProps = AsProps &
  CSSProps &
  HTMLAttributes<HTMLDivElement> &
  Pick<IconProps, 'variant'> & {
    icon?: IconProps['icon'];
    size?: 'small' | 'large';
    look?: BannerBadgeLook;
  };

const StyledIcon = styled(Icon, {});

export const bannerBadgeContainerStyle = css({
  variants: {
    look: {
      supplementary: {
        backgroundColor: '$neutral200',
        color: '$text-standard',
      },
      highlight: {
        backgroundColor: '$text-standard',
        color: '$text-button-inverse',
        [`& ${StyledIcon} > svg`]: {
          fill: '$text-button-inverse',
        },
      },
      inactive: {
        backgroundColor: 'transparent',
        boxShadow: 'inset 0px 0px 0px 1px $colors$text-disabled',
        color: '$text-disabled',
        [`& ${StyledIcon} > svg`]: {
          fill: '$text-disabled',
        },
      },
      optional: {
        backgroundColor: '$petrol0',
        boxShadow: 'inset 0px 0px 0px 1px $colors$text-disabled',
        color: '$text-helper',
        [`& ${StyledIcon} > svg`]: {
          fill: '$text-helper',
        },
      },
      primary: {
        backgroundColor: '$primary',
        color: '$text-button',
        [`& ${StyledIcon} > svg`]: {
          fill: '$text-button',
        },
      },
      'secondary-1': {
        backgroundColor: '$secondary-1',
        color: '$text-button-inverse',
        [`& ${StyledIcon} > svg`]: {
          fill: '$text-button-inverse',
        },
      },
      'secondary-2': {
        backgroundColor: '$secondary-2',
        color: '$text-button',
        [`& ${StyledIcon} > svg`]: {
          fill: '$text-button',
        },
      },
      'secondary-3': {
        backgroundColor: '$secondary-3',
        color: '$text-button',
        [`& ${StyledIcon} > svg`]: {
          fill: '$text-button',
        },
      },
      'secondary-4': {
        backgroundColor: '$secondary-4',
        color: '$text-button',
        [`& ${StyledIcon} > svg`]: {
          fill: '$text-button',
        },
      },
      'secondary-5': {
        backgroundColor: '$secondary-5',
        color: '$text-button',
        [`& ${StyledIcon} > svg`]: {
          fill: '$text-button',
        },
      },
      'secondary-6': {
        backgroundColor: '$secondary-6',
        color: '$text-button-inverse',
        [`& ${StyledIcon} > svg`]: {
          fill: '$text-button-inverse',
        },
      },
      success: {
        backgroundColor: '$success',
        color: '$text-button',
        [`& ${StyledIcon} > svg`]: {
          fill: '$text-button',
        },
      },
      alert: {
        backgroundColor: '$warning',
        color: '$text-button',
        [`& ${StyledIcon} > svg`]: {
          fill: '$text-button',
        },
      },
      error: {
        backgroundColor: '$error',
        color: '$text-button',
        [`& ${StyledIcon} > svg`]: {
          fill: '$text-button',
        },
      },
    },
  },
});

const IconContainer = styled(Box);

const Container = styled(Box, bannerBadgeContainerStyle, {
  borderRadius: '$space$component-2',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingX: '$component-2',
  paddingY: 1,
  height: 'min-content',
  variants: {
    size: {
      small: {
        [`& ${IconContainer}`]: {
          padding: 3,
        },
        [`& ${StyledIcon}`]: {
          position: 'relative',
          size: 12,
          minHeight: 12,
          minWidth: 12,
          '& > svg': {
            size: 12,
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          },
        },
      },
      large: {
        padding: '$component-3',
      },
    },
  },
});

export const BannerIconBadge = forwardRef<HTMLDivElement, BannerIconBadgeProps>(
  (
    {
      size = 'small',
      look = 'supplementary',
      icon = banking___atm,
      variant = 'outline',
      ...rest
    },
    forwardedRef,
  ) => {
    return (
      <Container ref={forwardedRef} {...rest} size={size} look={look}>
        <IconContainer>
          <StyledIcon
            size="small"
            icon={icon}
            variant={variant}
            aria-hidden="true"
          />
        </IconContainer>
      </Container>
    );
  },
);
BannerIconBadge.displayName = 'BannerIconBadge';
