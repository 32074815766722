/* eslint-disable cds/no-next-imports */
import NextLink from 'next/link';

import { makeLinkTargetProps } from '@utils/helpers/linkHelpers';

import { useMakeLink } from './Link.hooks';
import {
  Link as NeuiLink,
  LinkProps as NeuiLinkProps,
} from '../neui-components/atoms/Link';

export type LinkProps = NeuiLinkProps &
  Omit<Parameters<typeof NextLink>[0], 'href'>;

export function Link({ href: inboundHref, ...rest }: LinkProps) {
  const makeLink = useMakeLink();
  const href = makeLink({ href: inboundHref });
  const linkProps = makeLinkTargetProps(href);

  const linkInnards = (
    <NeuiLink target="_self" {...linkProps} {...rest}></NeuiLink>
  );

  return href !== undefined ? (
    <NextLink href={href} passHref>
      {linkInnards}
    </NextLink>
  ) : (
    linkInnards
  );
}
