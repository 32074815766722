import { AsProps, CSSProps, VariantProps, styled } from '@neui/core';
import { Typography, TypographyProps } from '@neui/styleguide-commerzbank';
import { AnchorHTMLAttributes, forwardRef } from 'react';

export type LinkProps = CSSProps &
  AsProps &
  AnchorHTMLAttributes<HTMLAnchorElement> &
  TypographyProps &
  VariantProps<typeof StyledLink> & {
    active?: boolean;
    disabled?: boolean;
  };

const StyledTypography = styled(Typography, {
  transition: 'all 100ms ease',
});

const StyledLink = styled('a', {
  all: 'unset',
  [`& ${StyledTypography}`]: {
    color: '$text-standard',
  },
  '&:not([disabled])': {
    cursor: 'pointer',
    '&:hover, &:focus, &[data-active="true"]': {
      [`& ${StyledTypography}`]: {
        color: '$text-standard',
      },
    },
  },
  '&[disabled]': {
    [`& ${StyledTypography}`]: {
      color: '$text-disabled',
    },
    pointerEvents: 'none',
  },
  variants: {
    underlined: {
      true: {
        [`& ${StyledTypography}`]: {
          display: 'inline',
          position: 'relative',
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          borderBottomColor: '$text-standard',
        },
        '&[disabled]': {
          [`& ${StyledTypography}`]: {
            borderBottomColor: '$text-disabled',
          },
        },
        '&:not([disabled])': {
          [`& ${StyledTypography}`]: {
            borderBottomColor: '$text-standard',
          },
          '&:hover, &:focus, &:active': {
            [`& ${StyledTypography}`]: {
              borderBottomWidth: 3,
            },
          },
        },
      },
      false: {},
    },
    outlined: {
      true: {
        '&:not([disabled])': {
          '&:focus-visible': {
            outline: '2px solid $text-standard',
            outlineOffset: 4,
            border: 'none',
            borderRadius: 4,
          },
        },
      },
      false: {},
    },
  },
});

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      children,
      renderAs = 'a',
      active,
      underlined = false,
      weight = 'medium',
      size = 5,
      disabled,
      ...rest
    },
    forwardedRef,
  ) => {
    return (
      <StyledLink
        ref={forwardedRef}
        underlined={underlined}
        as={renderAs}
        data-active={active}
        tabIndex={disabled ? -1 : 0}
        disabled={disabled}
        {...rest}
      >
        <StyledTypography weight={weight} size={size}>
          {children}
        </StyledTypography>
      </StyledLink>
    );
  },
);
Link.displayName = 'Link';
